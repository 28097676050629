<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>

    <b-navbar-nav
      class="nav user-details-nav align-items-center ml-auto profile-card"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ adminFirstName || '' }} {{ adminLastName || ''}}
            </p>
            <p class="user-email mb-0">{{ userEmail }}</p>
          </div>
          <div v-if="profileImage && profileImage !== 'undefined'">
            <img
              :src="profileImage"
              alt="Profile Image"
              class="rounded-circle profile-main-image-logo mx-auto"
            />
          </div>
          <div v-else>
            <b-avatar size="40" class="avtar-color" :text="getInitials" />
          </div>

          <feather-icon size="30" icon="MoreVerticalIcon" class="mr-20" />
        </template>

        <b-dropdown-item
          @click="openUpdateAdminDetails"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile </span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          @click="openLogoutModal"
          link-class="d-flex align-items-center text-danger"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-modal
        no-close-on-backdrop
        id="modal-center"
        hide-footer
        hide-header
        centered
        size="small"
        v-model="logoutModal"
      >
        <b-card-text>
          <div
            class="header text-right"
            style="margin-top: -10px;margin-right:-18px"
          >
            <b-button
              variant="flat-primary"
              @click="closeLogoutModal"
              class="disable-hover"
              ><feather-icon icon="XIcon"></feather-icon
            ></b-button>
          </div>
          <div>
            <h5 class="deletemodal-text">
              Are you sure want to Logout?
            </h5>
          </div>
          <div class="buttons">
            <b-button
              class="button-margin"
              style="width: 140px;"
              variant="outline-secondary"
              @click="closeLogoutModal"
            >
              Cancel
            </b-button>
            <b-button variant="danger" @click="logout" style="width: 140px;">
              Logout
            </b-button>
          </div>
        </b-card-text>
      </b-modal>
    </b-navbar-nav>
    <b-modal
      no-close-on-backdrop
      v-model="updateAdminDetails"
      size="small"
      hide-footer
      hide-header
      centered
    >
      <div class="editProfileDetails">
        <div
          class="modal-item"
          style="display: flex; justify-content: space-between;"
        >
          <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
            <h3 class="app-title sm">
              Profile Details
            </h3>
          </div>
          <div>
            <b-button @click="closeUpdateAdminDetails" variant="flat-primary"
              ><feather-icon icon="XIcon"></feather-icon
            ></b-button>
          </div>
        </div>
        <b-icon
          v-if="this.isUpdateProfile"
          @click="closeEditForm"
          class="upDownIcon"
          icon="arrow-left-circle-fill"
          variant="primary"
          style="cursor: pointer; font-size:20px; margin-top:10px"
        />
        <div
          class="profile-card animate-edit card shadow-sm p-1 mb-2 bg-white rounded text-center"
        >
          <!-- Profile Image -->
          <div class="image-container" v-if="!this.isUpdateProfile">
            <div v-if="profileImage && profileImage !== 'undefined'">
              <img
                :src="profileImage"
                alt="Profile Image"
                class="rounded-circle profile-image mx-auto"
              />
            </div>

            <div v-else>
              <b-avatar size="200" class="avtar-color" :text="getInitials" />
            </div>
            <div
              style="position: absolute; top: 165px; right: 205px;background-color:#704c94; border-radius:30px; width:30px; height:30px;"
            >
              <b-icon
                @click="openEditForm"
                class="upDownIcon"
                icon="pencil-square"
                style=" cursor: pointer; color:white; font-size:25px; margin-top:5px "
              />
            </div>
          </div>
          <div class="mt-2" v-if="!this.isUpdateProfile">
            <h5>{{ this.adminFirstName }} {{ this.adminLastName }}</h5>
            <p class="text-muted">{{ userEmail }}</p>
          </div>
          <div v-else class="animate-edit mt-2 mb-2">
            <template>
              <div class="image-upload-container" @click="triggerFileInput">
                <input
                  type="file"
                  accept="image/*"
                  @change="previewImage"
                  class="file-input"
                  ref="fileInput"
                  style="display: none;"
                />
                <div v-if="imagePreview" class="image-preview">
                  <img :src="imagePreview" alt="Image Preview" />
                </div>
                <div v-else class="upload-message">
                  <p class="p-4">
                    Drag and drop your image here, or click to select an image
                  </p>
                </div>
              </div>
            </template>
            <div style="display: flex; gap:5px">
              <b-form-input
                placeholder="Explore Vuexy..."
                v-model="adminEditFirstName"
              />
              <b-form-input
                id="boomark-search-input"
                placeholder="Explore Vuexy..."
                v-model="adminEditLastName"
              />
            </div>
            <b-button
              variant="primary"
              @click="updateAdminProfileDetails"
              class="mt-1"
              block
            >
              Update
              <b-spinner v-if="isUpdatingProfileDetails" small class="mr-1" />
            </b-button>
          </div>
          <p
            v-if="!this.isUpdateProfile"
            class="text-muted"
            style="text-align: left; margin-left:5px;margin-bottom:5px; font-weight:bold"
          >
            Access Granted:
          </p>
          <template class="animate-edit" v-if="!this.isUpdateProfile">
            <div class="access-granted">
              <div class="access-control-list">
                <ul style="margin-left: 5px;">
                  <li  class="sub-item text-muted">
                    Client : <b>Granted</b>
                  </li>
                  <li  class="sub-item text-muted">
                    Triggers : <b> {{ this.showTrigers === 'true' ? 'Granted' : 'Non-Granted' }}</b>
                  </li>
                  <li  class="sub-item text-muted">
                    Access Control : <b> {{ this.showAccessControl === 'true' ? 'Granted' : 'Non-Granted' }}</b>
                  </li>
                  <li  class="sub-item text-muted">
                    GMB : <b>  {{ this.showGMB === 'true' ? 'Granted' : 'Non-Granted' }}</b>
                  </li>
                  <li  class="sub-item text-muted">
                    GMB For Dealers:<b>  {{ this.showGMBForDealers === 'true' ? 'Granted' : 'Non-Granted' }}</b>
                  </li>
                  <!-- <li v-for="(value, key) in data" :key="key" class="main-item">
                    <strong>{{ key }}:</strong>
                    <ul v-if="typeof value === 'object'" class="sub-list">
                      <li
                        v-for="(subValue, subKey) in value.items"
                        :key="subKey"
                        class="sub-item"
                      >
                        {{ subKey }}:
                        <span
                          :class="{
                            granted: subValue,
                            'non-granted': !subValue,
                          }"
                        >
                          {{ subValue ? "Granted" : "Non-Granted" }}
                        </span>
                      </li>
                    </ul>
                    <span v-else>
                      <span :class="{ granted: value, 'non-granted': !value }">
                        {{ value ? "Granted" : "Non-Granted" }}
                      </span>
                    </span>
                  </li> -->
                </ul>
              </div>
            </div>
          </template>
          <!-- Edit Button -->
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BModal,
  BCardText,
  BButton,
  BIcon,
  BFormInput,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import {
  getUserData,
  getUserToken,
  getUserEmail,
  getUserImage,
} from "@/auth/utils";
import useJwt from "@/auth/jwt/useJwt";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      isUpdatingProfileDetails: false,
      imagePreview: null,
      adminFirstName: "",
      adminEditFirstName: "",
      adminLastName: "",
      adminEditLastName: "",
      adminID: "",
      isUpdateProfile: false,
      geoEnabled: null,
      showClient: null,
      showGMB: null,
      showTrigers: null,
      showAccessControl: null,
      showGMBForDealers: null,
      storesEnabled: null,
      data: {
        gmb: true,
        gmb_for_dealers: true,
        triggers: false,
        access_control: true,
        clients: true,
      },
      name: "John Doe",
      email: "john.doe@example.com",
      profileImage: "",
      logoutModal: false,
      updateAdminDetails: false,
    };
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BModal,
    BCardText,
    DarkToggler,
    BButton,
    BIcon,
    BFormInput,
    ToastificationContent,
    BSpinner,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userDetails: function() {
      return getUserData();
    },
    userEmail: function() {
      return getUserEmail();
    },
    userImage: function() {
      return getUserImage();
    },
    getInitials: function() {
      var acronym = "";
      const userName = getUserData();
      if (userName) {
        var matches = userName.match(/\b(\w)/g);
        acronym = matches.join("");
      }
      return acronym;
    },
  },
  created() {
    this.adminEditFirstName = Cookies.get(useJwt.jwtConfig.adminFirstName);
    this.adminFirstName = Cookies.get(useJwt.jwtConfig.adminFirstName);
    this.profileImage = Cookies.get(useJwt.jwtConfig.adminUrl);
    this.adminLastName = Cookies.get(useJwt.jwtConfig.adminLastName);
    this.adminEditLastName = Cookies.get(useJwt.jwtConfig.adminLastName);
    this.adminID = Cookies.get(useJwt.jwtConfig.adminID);
    this.geoEnabled = Cookies.get(useJwt.jwtConfig.gmbGeo);
    this.showClient = Cookies.get(useJwt.jwtConfig.userClientKey);
    this.showGMB = Cookies.get(useJwt.jwtConfig.userGMBKey);
    this.showTrigers = Cookies.get(useJwt.jwtConfig.userTriggersKey);
    this.showAccessControl = Cookies.get(useJwt.jwtConfig.userAccessControlKey);
    this.showGMBForDealers = Cookies.get(useJwt.jwtConfig.userDealersKey);
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    triggerFileInput() {
      this.$refs.fileInput.click(); // Programmatically trigger file input
    },
    async updateAdminProfileDetails() {
      const formData = new FormData();
      if (this.imagePreview === null) {
        this.showToast("Error", "Please select an image to upload.", "danger");
        return;
      }
      const response = await fetch(this.imagePreview);
      const blob = await response.blob();
      formData.append("media", blob, "image.jpg");
      formData.append("firstname", this.adminEditFirstName);
      formData.append("lastname", this.adminEditLastName);
      formData.append("adminID", this.adminID);
      this.isUpdatingProfileDetails = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/update-profile-details/update-profile-details`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Success!",
            "Updated Profile Details Successfully",
            "success"
          );
          Cookies.remove(useJwt.jwtConfig.adminFirstName);
          Cookies.remove(useJwt.jwtConfig.adminUrl);
          Cookies.remove(useJwt.jwtConfig.adminLastName);
          this.checklogin();
          this.isUpdateProfile = false;
          this.isUpdatingProfileDetails = false;
        })
        .catch((error) => {
          this.showToast("Error", "Unknown error occurred.", "danger");
          console.error("Error uploading file:", error);
          this.isUpdatingProfileDetails = false;
        });
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    openEditForm() {
      this.isUpdateProfile = true;
    },
    closeEditForm() {
      this.isUpdateProfile = false;
    },
    openUpdateAdminDetails() {
      this.updateAdminDetails = true;
    },
    closeUpdateAdminDetails() {
      this.updateAdminDetails = false;
    },
    openLogoutModal() {
      this.logoutModal = true;
    },
    closeLogoutModal() {
      this.logoutModal = false;
    },
    logout() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/logout`,
          {},
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log("logging out");
          useJwt.removeUserData();
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
          Cookies.remove(useJwt.jwtConfig.storageTokenKeyName);
          Cookies.remove(useJwt.jwtConfig.storageRefreshTokenKeyName);
          Cookies.remove(useJwt.jwtConfig.userNameKey);
          Cookies.remove(useJwt.jwtConfig.userEmailKey);
          Cookies.remove(useJwt.jwtConfig.adminFirstName);
          Cookies.remove(useJwt.jwtConfig.adminLastName);
          Cookies.remove(useJwt.jwtConfig.adminUrl);
          Cookies.remove(useJwt.jwtConfig.adminID);
          this.$router.push({ name: "login" });
          this.closeLogoutModal();
        })
        .catch((error) => {
          console.log(error.response);
          useJwt.removeUserData();
          this.$router.push({ name: "login" });
        });
    },
    getToken() {
      return Cookies.get(useJwt.jwtConfig.storageTokenKeyName);
    },
    checklogin() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/admin/check-login`, {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        })
        .then((response) => {
          this.adminEditFirstName = response.data.firstname;
          this.adminEditLastName = response.data.lastname;
          this.adminFirstName = response.data.firstname;
          this.adminLastName = response.data.lastname;
          this.profileImage = response.data.adminProfileImageUrl.s3URL;
          // window.location.reload(true);
        })
        .catch((error) => {
          alert("error");
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
  },
};
</script>
<style lang="scss">
.editProfileDetails {
  .access-granted {
    .access-control-list {
      font-family: Arial, sans-serif;
      border-radius: 8px;
      width: 450px;
      justify-content: left;
      align-items: left;
      text-align: left;
    }

    ul {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      text-align: left;
    }

    .main-item {
      width: 50%;
      padding: 5px;
      box-sizing: border-box;
    }

    .item-content {
      background-color: #fff;
      padding: 5px;
      border: 1px solid #ddd;
      border-radius: 5px;
    }

    .sub-list {
      display: flex;
      flex-wrap: wrap;
    }

    .sub-item {
      width: 50%;
      padding: 5px 0;
    }

    .granted {
      color: green;
      font-weight: bold;
    }

    .non-granted {
      color: red;
      font-weight: bold;
    }
  }
  .image-container {
    position: relative;
    display: inline-block;
  }
  .animate-edit {
    animation-duration: 1s; /* Increase the duration to 1s for a smoother effect */
    animation-name: animate-fade-move;
    animation-fill-mode: forwards; /* Change to 'forwards' to keep the final state */
  }

  @keyframes animate-fade-move {
    0% {
      opacity: 0;
      transform: translateX(50px); /* Adjust the value based on your needs */
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .image-upload-container {
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 10px;
    border: 2px dashed #ddd;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }

  .image-upload-container:hover {
    border-color: #4caf50;
  }

  .file-input {
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .upload-message {
    color: #888;
    font-size: 16px;
  }

  .image-preview img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .profile-image {
    width: 200px;
    height: 200px;
    border: 5px solid #704c94;
    object-fit: cover;
  }
  .profile-image-logo {
    width: 40px;
    height: 40px;
    border: 2px solid #704c94;
    object-fit: cover;
  }
}

.profile-card {
  background-color: #f5f6fa;
}
.avtar-color {
  background-color: #f5edff !important;
}
.navbar[class*="bg-"] .navbar-nav .nav-item > a span {
  color: #9747ff !important;
}
.navbar[class*="bg-"] .navbar-nav .nav-item > a svg {
  color: #bbbbbb !important;
}
.deletemodal-text {
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #200e6b;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.buttons {
  margin-bottom: 30px;
  display: flex;
  gap: 30px;
  padding: 20px 20px;
  justify-content: center;
}

.button-margin {
  margin-right: 0;
}
.disable-hover {
  color: #bbbbbb;
}

.upDownIcon {
  color: #000;
  font-size: 20px;
}

.profile-main-image-logo {
  width: 40px;
  height: 40px;
  border: 2px solid #704c94;
  object-fit: cover;
}
</style>
